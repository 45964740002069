<template>
  <nav class="navbar navbar-expand-lg header">
    <div class="container-fluid">
      <router-link
        to="/"
        class="navbar-brand"
      >
        Edge Administration Shell
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        id="navbarSupportedContent"
        class="collapse navbar-collapse"
      >
        <!-- Device or machine list -->
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
              id="devices-nav-link"
              :to="{ name: Routes.DEVICE }"
              :class="`nav-link ${router.currentRoute.value.name == Routes.DEVICE ? 'active' : ''}`"
            >
              Devices
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              id="machine-nav-link"
              :to="{ name: Routes.MACHINE }"
              :class="`nav-link ${
                router.currentRoute.value.name == Routes.MACHINE ? 'active' : ''
              }`"
            >
              Machines
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              id="group-nav-link"
              :to="{ name: Routes.GROUP }"
              :class="`nav-link ${router.currentRoute.value.name == Routes.GROUP ? 'active' : ''}`"
            >
              Groups
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              id="group-nav-link"
              :to="{ name: Routes.QUERY_SEARCH }"
              :class="`nav-link ${
                router.currentRoute.value.name == Routes.QUERY_SEARCH ? 'active' : ''
              }`"
            >
              Query Search
            </router-link>
          </li>
          <li
            v-if="isAdmin"
            class="nav-item"
          >
            <router-link
              id="admin-nav-link"
              :to="{ name: Routes.ADMIN }"
              :class="`nav-link ${router.currentRoute.value.name == Routes.ADMIN ? 'active' : ''}`"
            >
              Admin
            </router-link>
          </li>
        </ul>
        <!-- Search device -->
        <div class="d-flex m-3">
          <div>
            <form
              class="input-group"
              style="width: 15rem"
              @submit.prevent="submit"
            >
              <input
                v-model="searchValue"
                class="form-control"
                type="search"
                placeholder="Device / Machine Name"
                aria-label="Search"
              />
              <button
                id="btn-search"
                class="btn btn-outline-dark"
              >
                <S2cIcon name="mu-icon-monitor" />
              </button>
            </form>
            <div
              v-if="searchValue"
              id="input-result"
              class="bg-secondary"
            >
              <span
                :style="[is_valid_response() ? { color: 'lightgreen' } : { color: 'red' }]"
                style="margin: 0.3125rem"
              >
                <S2cIcon
                  v-if="!is_valid_response()"
                  name="mu-icon-x"
                  color="'red'"
                />
                <S2cIcon
                  v-else
                  name="mu-icon-check"
                  color="'green'"
                />
              </span>
              <span>
                {{ searchValue }}
              </span>
            </div>
          </div>
          <button
            id="btn-logout"
            class="align-self-baseline btn btn-outline-dark"
            style="margin-left: 1rem"
            @click.prevent="logout"
          >
            <S2cIcon name="mu-icon-logout" />
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { S2cIcon } from '@multivac-se/s2-components';
import { useMachineApi } from '@/http/machine/MachineApi';
import { useDeviceApi } from '@/http/device/DeviceApi';
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { Routes } from '@/router/routes';
import { useUserApi } from '@/http/user/UserApi';
import { scg } from 'ioc-service-container';
const devicePattern = /\d{3,}_\w{1,}_\w{1,}/g;
const machinePattern = /\w{1,}_\w{1,}/g;
const router = useRouter();
const userApi = useUserApi();

const searchValue = ref('');
const responseData = ref();
const searchMachine = ref(false);
const isAdmin = ref(false);

onMounted(async () => {
  const response = await userApi.isAdmin();
  if (response.value === true) {
    isAdmin.value = response.value;
  }
});

async function logout() {
  await scg('Auth').logoutRedirect();
}
function is_valid_response() {
  if (
    responseData.value === undefined ||
    (Array.isArray(responseData.value) && responseData.value.length === 0)
  ) {
    return false;
  }
  return true;
}
function submit() {
  if (responseData.value !== undefined) {
    const data = responseData.value[0];
    if (searchMachine.value) {
      router.push({ name: 'machine', query: { search: data.name } });
    } else {
      router.push({ name: 'device', query: { search: data.name } });
    }
    responseData.value = undefined;
    searchValue.value = '';
  }
}
watch(searchValue, async (newSearchValue: string) => {
  searchValue.value = searchValue.value.replaceAll(' ', '');
  if (newSearchValue.match(devicePattern)) {
    searchMachine.value = false;
    responseData.value = await useDeviceApi().fetchDevices({ name: searchValue.value, items: 1 });
  } else if (newSearchValue.match(machinePattern)) {
    searchMachine.value = true;
    responseData.value = await useMachineApi().fetchMachines({ name: searchValue.value, items: 1 });
  } else {
    responseData.value = undefined;
  }
});
</script>

<style scoped>
#input-result {
  margin-top: 0.3125rem;
  border-radius: 0.3125rem;
  position: absolute;
  width: 15rem;
}

.header {
  background-color: #fff;
}
</style>
