<template>
  <div class="s4-layout-container">
    <NavBar class="layout-container-header" />
    <div class="layout-container-main">
      <div class="fullscreen-content">
        <div class="fullscreen-grid-row">
          <S2cLoadingIndicator :loading="isLoading().value" />
          <S2cFilterBar class="align-items-center" />
        </div>
        <router-view />
      </div>
      <S2cToastContainer
        ref="toastContainerRef"
        :is-filtering-duplicates="false"
      />
    </div>
  </div>
</template>

<script setup>
import { S2cFilterBar, S2cLoadingIndicator, S2cToastContainer } from '@multivac-se/s2-components';
import { useToast } from '@/store/toast';
import { useLoadingStore } from '@multivac-se/muwo-frontend-shared';
import { ref, onMounted } from 'vue';
import NavBar from './components/navbar/NavBar.vue';

const { isLoading } = useLoadingStore().withContext('eas');

const toast = useToast();
const toastContainerRef = ref(null);
onMounted(() => toast.registerContainer(toastContainerRef.value));
</script>

<style lang="scss" scoped>
.fullscreen-content {
  background-color: #f2f2f2;
  display: grid;
  grid-auto-rows: auto 1fr;
  min-height: 100%;
}
</style>
