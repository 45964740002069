import { registerAuth, registerI18n, registerRouter } from '@multivac-se/muwo-frontend-shared';
import { App } from 'vue';
import { routes } from './router';
import { scg } from 'ioc-service-container';
import MU_ICONS from '@multivac-se/s4-icon-font/dist/multivac-icons.json';

export const MU_ICON_KEYS = Object.keys(MU_ICONS);

const supportedLanguageCodes = [
  'en',
  'de',
  'cs',
  'es',
  'fr',
  'it',
  'ja',
  'ru',
  'hu',
  'fi',
  'nl',
  'pl',
  'zh',
  'zh-CN',
  'zh-HK',
];

export async function setupApp(app: App) {
  await registerAuth();
  registerRouter(routes);
  await registerI18n(supportedLanguageCodes);

  const router = scg('Router');
  const i18n = scg('I18n');

  app.use(router);
  app.use(i18n);
  app.mount('#app');
}
