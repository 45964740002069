import { Endpoint } from '../endpoint/Endpoint.interface';
import { fromEndpointDto } from '../endpoint/Endpoint.utils';
import { Machine, MachineDTO } from './Machine.interface';

export function fromMachineDto(dto: MachineDTO): Machine {
  const endpoints = dto.endpoints.map<Endpoint>(fromEndpointDto);

  return {
    id: dto.id,
    deviceId: dto.device_id,
    name: dto.name,
    createDate: new Date(dto.created_at),
    endpoints: endpoints,
    swVersion: dto.sw_version,
  };
}
