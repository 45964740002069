export enum Routes {
  HOME = 'home',
  DEVICE = 'device',
  MACHINE = 'machine',
  QUERY = 'query',
  QUERY_SEARCH = 'querySearch',
  ADMIN = 'admin',
  GROUP = 'group',
  GROUP_DETAILS = 'groupDetails',
}
