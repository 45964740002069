export interface User {
  email: string;
  role: UserRole;
}

export interface IdUser extends User {
  id: string;
}

export enum UserRole {
  USER = 'user',
  ADMIN = 'admin',
}
