import { ref } from 'vue';
import { http } from '..';
import { IdUser, User, UserRole } from './User.interface';

function me(): Promise<IdUser> {
  return http.get<IdUser>('v1/auth/me');
}

function all(): Promise<IdUser[]> {
  return http.get<IdUser[]>('v1/auth');
}

function remove(userId: string): Promise<IdUser> {
  return http.delete<IdUser>(`v1/auth/${userId}`);
}

function create(user: User): Promise<IdUser> {
  return http.post<IdUser>('v1/auth', user);
}

const isAdminCache = ref<boolean | null>(null);
async function isAdmin() {
  if (isAdminCache.value === null) {
    const user = await me();
    if (user.role === UserRole.ADMIN) {
      isAdminCache.value = true;
    } else {
      isAdminCache.value = false;
    }
  }
  return isAdminCache;
}

export function useUserApi() {
  return {
    me,
    all,
    remove,
    create,
    isAdmin,
  };
}
