import { createApp } from 'vue';
import App from './App.vue';
import {
  checkBrowserCompatibility,
  createLogger,
  setupDocument,
} from '@multivac-se/muwo-frontend-shared';
import '@multivac-se/s2-components/src/scss/global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { setupApp } from './setupApp';

(async () => {
  try {
    checkBrowserCompatibility();
    setupDocument();
    const app = createApp(App);
    await setupApp(app);
  } catch (e) {
    createLogger('main').error('An error occurred during setup', e);
  }
})();
