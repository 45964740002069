import { fromMachineDto } from '../machine/Machine.utils';
import { Device, DeviceDTO } from './Device.interface';

export function fromDeviceDto(dto: DeviceDTO): Device {
  return {
    id: dto.id,
    group: dto.group,
    hilscherId: dto.hilscher_id,
    createDate: new Date(dto.created_at),
    deleteDate: dto.deleted_at ? new Date(dto.deleted_at) : null,
    name: dto.name,
    online: dto.online,
    machines: dto.machines?.map(fromMachineDto) ?? [],
  };
}
