import {
  Endpoint,
  EndpointCreate,
  EndpointCreateDTO,
  EndpointDTO,
  EndpointTypes,
  EndpointUpdate,
  EndpointUpdateDTO,
} from './Endpoint.interface';

export function fromEndpointDto(dto: EndpointDTO): Endpoint {
  return {
    id: dto.id,
    machineId: dto.machine_id,
    changeDate: new Date(dto.last_updated),
    createDate: new Date(dto.created_at),
    endpointType: dto.endpoint_type.toLowerCase() as EndpointTypes,
    online: dto.online,
    url: dto.url,
    manufacturer: dto.manufacturer,
    assetType: dto.asset_type,
    location: dto.location,
    description: dto.description,
    machineName: dto.machine_name,
  };
}

export function toEndpointCreateDto(create: EndpointCreate): EndpointCreateDTO {
  return {
    endpoint_type: create.endpointType,
    machine_id: create.machineId,
    online: create.online,
    url: create.url,
    manufacturer: create.manufacturer,
    asset_type: create.assetType,
    location: create.location,
    description: create.description,
  };
}

export function toEndpointUpdateDto(update: EndpointUpdate): EndpointUpdateDTO {
  return {
    asset_type: update.assetType,
    description: update.description,
    location: update.location,
    manufacturer: update.manufacturer,
    online: update.online,
    url: update.url,
  };
}
