import { Device, DeviceDTO } from './Device.interface';
import { http } from '..';
import { DataCursor } from '../Cursor.interface';
import { fromDeviceDto } from './Device.utils';

async function fetchDevices(params: {
  name?: string;
  group_id?: string;
  cursor?: string | null;
  items: number;
}): Promise<DataCursor<Device>> {
  const response = await http.get<DataCursor<DeviceDTO>>('/v3/devices', params);
  const devices = response.data.map(fromDeviceDto);
  return {
    cursor: response.cursor,
    data: devices,
  };
}

async function fetchDevice(deviceId: string): Promise<Device> {
  const deviceDto = await http.get<DeviceDTO>(`/v3/devices/${deviceId}`);
  return fromDeviceDto(deviceDto);
}

async function updateDeviceGroup(deviceId: string, groupId: string): Promise<Device> {
  const device = await http.put<DeviceDTO>(`/v3/devices/${deviceId}`, { group_id: groupId });
  return fromDeviceDto(device);
}

export function useDeviceApi() {
  return {
    fetchDevices,
    fetchDevice,
    updateDeviceGroup,
  };
}
