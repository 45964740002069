import { Machine, MachineDTO } from '@/http/machine/Machine.interface';
import { http } from '..';
import { DataCursor } from '../Cursor.interface';
import { fromMachineDto } from './Machine.utils';

async function fetchMachines(params: {
  name?: string;
  cursor?: string;
  items: number;
}): Promise<DataCursor<Machine>> {
  const response = await http.get<DataCursor<MachineDTO>>('/v2/machines', params);
  const machines = response.data.map(fromMachineDto);
  return {
    cursor: response.cursor,
    data: machines,
  };
}

async function fetchMachine(machineId: string): Promise<Machine> {
  const machineDto = await http.get<MachineDTO>(`/machines/${machineId}`);
  return fromMachineDto(machineDto);
}

async function transferMachine(machineId: string, deviceId: string): Promise<Machine> {
  const machineDto = await http.put<MachineDTO>(`/machines/${machineId}`, { device_id: deviceId });
  return fromMachineDto(machineDto);
}

async function deleteMachine(machineId: string): Promise<Machine> {
  const machineDto = await http.delete<MachineDTO>(`v2/machines/${machineId}`);
  return fromMachineDto(machineDto);
}

export function useMachineApi() {
  return {
    fetchMachines,
    fetchMachine,
    transferMachine,
    deleteMachine,
  };
}
