import { Routes } from './routes';
import { RouteRecordRaw } from 'vue-router';
export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: Routes.HOME,
    redirect: { name: Routes.DEVICE },
  },
  {
    path: '/machine',
    name: Routes.MACHINE,
    component: () => import('../views/MachineView.vue'),
  },
  {
    path: '/device',
    name: Routes.DEVICE,
    component: () => import('../views/DeviceView.vue'),
  },
  {
    path: '/query/:deviceId/:machineId',
    name: Routes.QUERY,
    component: () => import('../views/QueryView.vue'),
  },
  {
    path: '/querySearch',
    name: Routes.QUERY_SEARCH,
    component: () => import('../views/QuerySearchView.vue'),
  },
  {
    path: '/admin',
    name: Routes.ADMIN,
    component: () => import('../views/AdminView.vue'),
  },
  {
    path: '/group',
    name: Routes.GROUP,
    component: () => import('../views/GroupView.vue'),
  },
  {
    path: '/group/details',
    name: Routes.GROUP_DETAILS,
    component: () => import('../views/GroupDetailsView.vue'),
  },
  {
    path: '/error/:status',
    name: 'Error',
    component: () => import('../views/ErrorView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Error', params: { status: 404 } },
  },
];
