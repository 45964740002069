import { useToast } from '@/store/toast';
import { readSharedEnvValueOrThrow, useLoadingStore } from '@multivac-se/muwo-frontend-shared';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { scg } from 'ioc-service-container';

const apiUrl = readSharedEnvValueOrThrow('API_BASE_URL');
const toast = useToast();
const loading = useLoadingStore().withContext('eas');

interface Params {
  [key: string]: string | number | null;
}

class HTTP {
  private axiosInstance: AxiosInstance;

  constructor(baseUrl: string) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
    });
    this.axiosInstance.interceptors.request.use(
      async (config) => {
        loading.startLoading();
        const token = await scg('Auth').getIDToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
        loading.stopLoading();
        const { msg, stack } = this.formatError(error);
        toast.showError(msg, stack);
      },
    );
    this.axiosInstance.interceptors.response.use(
      (res) => {
        loading.stopLoading();
        return res;
      },
      (error) => {
        loading.stopLoading();
        const { msg, stack } = this.formatError(error);
        toast.showError(msg, stack);
      },
    );
  }

  async get<T>(url: string, params?: Params): Promise<T> {
    const { data } = await this.axiosInstance.get<T>(url, { params });
    return data;
  }

  async post<T>(url: string, body: unknown): Promise<T> {
    const { data } = await this.axiosInstance.post<T>(url, body);
    return data;
  }

  async delete<T>(url: string): Promise<T> {
    const { data } = await this.axiosInstance.delete<T>(url);
    return data;
  }

  async put<T>(url: string, body: unknown): Promise<T> {
    const { data } = await this.axiosInstance.put<T>(url, body);
    return data;
  }

  private isAxiosError(error: unknown): error is AxiosError<{ detail: string }> {
    return axios.isAxiosError(error);
  }

  formatError(error: unknown) {
    let msg: string;
    let stack: string | undefined;
    if (this.isAxiosError(error)) {
      msg = String(error.response?.data.detail);
      stack = error.stack;
    } else if (error instanceof Error) {
      msg = error.message;
      stack = error.stack;
    } else {
      msg = String(error);
    }
    return {
      msg,
      stack,
    };
  }
}

export const http = new HTTP(apiUrl);
